import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { CategoriesProvider } from './store/providers/CategoriesContext';
import { setProducts } from "./store/slices/product-slice"
import fetchProducts from "./data/productcheck";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";

// Stateful component to handle loading
const ProductLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const products = await fetchProducts();
        console.log("Products loaded:", products);
        store.dispatch(setProducts(products));
        setLoading(false);  // Set loading to false once products are loaded and dispatched
      } catch (error) {
        console.error('Error loading products:', error.message);
      }
    };

    loadProducts();
  }, []);

  if (loading) {
    return <div className="flone-preloader-wrapper">
      <div className="flone-preloader">
        <span></span>
        <span></span>
      </div>
    </div>;  // Show a loading message or spinner while loading
  }

  return children;  // Render children (your app) once loading is done
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <CategoriesProvider>
        <ProductLoader>
          <App />
        </ProductLoader>
      </CategoriesProvider>
    </PersistProvider>
  </Provider>
);
