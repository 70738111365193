import { Suspense, lazy } from "react";
import axios from 'axios';
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";






axios.defaults.baseURL = "https://adminqatar.domi-pack.com/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';


axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
//axios.defaults.withXSRFToken = true;

// home pages
const HomeElectronicsTwo = lazy(() =>
  import("./pages/home/HomeElectronicsTwo")
);


// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Solutions = lazy(() => import("./pages/other/solution"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

// admin-sec
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const AdminLogin = lazy(() => import("./pages/other/adminLogin"));

const MasterLayout = lazy(() => import("./admin/masterLayout"));
const Dashboard = lazy(() => import("./admin/pages/dashboard"));
const Profile = lazy(() => import("./admin/pages/profile"));
const Category = lazy(() => import("./admin/pages/category/category"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<HomeElectronicsTwo />}

            // element={<HomeFashion/>}
            />

            {/* Homepages */}



            {/* Shop pages /shop-grid-standard */}
            <Route
              path={process.env.PUBLIC_URL + "/products"}
              element={<ShopGridStandard />}
            />

            {/* Shop product pages /product/:id */}
            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<Product />}
            />

            {/* Blog pages */}
            <Route
              path={process.env.PUBLIC_URL + "/blog-standard"}
              element={<BlogStandard />}
            />


            {/* Other pages */}
            <Route
              path={process.env.PUBLIC_URL + "/about"}
              element={<About />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/domipack-solutions"}
              element={<Solutions />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-account"}
              element={<MyAccount />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login-register"}
              element={
                localStorage.getItem('auth_token') ? (
                  <Navigate to={process.env.PUBLIC_URL + "/admin"} />
                ) : (
                  <Navigate to={process.env.PUBLIC_URL + "/login"} />
                )
              }
              // element={<LoginRegister />}
            />


            <Route
              path={process.env.PUBLIC_URL + "/cart"}
              element={<Cart />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/compare"}
              element={<Compare />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />
            {/* <Route
              path={process.env.PUBLIC_URL + "/admin/*"}
              element={<MasterLayout />}
            /> */}

            {/* <Route
              path={process.env.PUBLIC_URL + "/login"}
              element={<AdminLogin />}
            /> */}
            <Route
              path={process.env.PUBLIC_URL + "/admin/*"}
              element={
                localStorage.getItem('auth_token') ? (
                  <MasterLayout />
                 
                ) : (
                  <Navigate to={process.env.PUBLIC_URL + "/login"} />
                )
              }
            />

            <Route
              path={process.env.PUBLIC_URL + "/login"}
              element={
                localStorage.getItem('auth_token') ? (
                  <Navigate to={process.env.PUBLIC_URL + "/admin"} />
                ) : (
                  <AdminLogin />
                )
              }
            />


           
            <Route path="*" element={<NotFound />} />
           
            
          </Routes>
        </Suspense>
      </ScrollToTop>
      
    </Router>
  );
};

export default App;