import axios from 'axios';
import { stringify } from 'uuid';

const fetchProducts = async () => {
  try {
    const response = await axios.get('/api/view-product');
    if (!response.data) {
      throw new Error('Failed to fetch products');
    }
    let products = response.data.products;
    // console.log(products);

    let counter = 1;
     products = products.map(product => ({
      id: String(product.id),
     name: product.item_name,
     item_code: product.item_code,
     product_code: product.product_code,
     packing_detail: product.packing_detail,
     product_type: product.product_type,
     size: product.size,
     type:product.type,
     product_description: product.item_description,
     fullDescription:product.item_overview,

      category: [
        product.category,
        product.category2,
        product.category3

      ],

      tag: [
        product.category,
        product.subcategory,
        product.category2,
        product.subcategory2,
        product.category3,
        product.subcategory3
      ],
      stock: 1000,
      new:product.hot_sale === 1 ? true : null,
      image: [
        product.image1,
        product.image2,
        product.image3,
        product.image4,
        product.image5
      ]
    }));

    // console.log("prodtc1",products);


    
    

    return products;
  } catch (error) {
    console.error('Error fetching products:', error.message);
    return [];
  }
};

export default fetchProducts;
